import { MaisRentavelCards } from '~components/mobileCards/MaisRentavel'
import { PrecoProcedimento } from '~components/mobileCards/PrecoProcedimento'
import { MaisGlosam } from '~components/mobileCards/MaisGlosam'
import { RepassesPlanos } from '~components/mobileCards/RepassesPlanos'
import { Box } from '@mui/material'

interface ComparativosProps {
  value: number
  comparativesData: any
  convertedData: { value: string; plano: string }[]
  convertedDataQuick: { value: string; plano: string }[]
  convertDataInsurence: { value: unknown; plano: string; hospital: string }[]
  convertLestDataInsurence: { value: unknown; plano: string; hospital: string }[]
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function Comparativos({
  value,
  comparativesData,
  convertedData,
  convertedDataQuick,
  convertDataInsurence,
  convertLestDataInsurence,
}: ComparativosProps) {
  return (
    <TabPanel value={value} index={1}>
      <MaisRentavelCards
        description={comparativesData.most_profitable_insurance?.title}
        percentage={comparativesData.most_profitable_insurance?.percentage}
      />
      <PrecoProcedimento
        procedimento={comparativesData.price_per_procedure?.most_performed_procedure}
        isLess={false}
        totalValue={comparativesData.price_per_procedure?.average_price}
        topProcediments={convertDataInsurence}
      />
      <PrecoProcedimento
        procedimento={comparativesData.price_per_procedure?.most_performed_procedure}
        isLess={true}
        totalValue={comparativesData.price_per_procedure?.average_price}
        topProcediments={convertLestDataInsurence}
      />
      <MaisGlosam topProcediments={comparativesData?.most_glosed_insurances} />
      <RepassesPlanos isLess={false} topProcediments={convertedDataQuick} />
      <RepassesPlanos isLess={true} topProcediments={convertedData} />
    </TabPanel>
  )
}

export default Comparativos
