import api from '~services/api/api'

export const BaseService = api('https://api.production.seiwa.app', false)
export const SlackService = api(
  'https://hooks.slack.com/services/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',
  false,
)
export const BaseFileService = api('https://api.production.seiwa.app', true)
export const ApiVersion = '2.5.1'
/* export const ApiVersion = '2.5.1' */
