import { ApiVersion, BaseService } from '~services/api'

export const GetUserHome = (Uid: string, access: string, client: string, id: string) => {
  return BaseService.get(`/institution_api/v1/users/${id}/dashboard`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserComparatives = (Uid: string, access: string, client: string, id: string) => {
  return BaseService.get(`/institution_api/v1/user_comparatives?user_hashid=${id}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserRetrospects = (Uid: string, access: string, client: string, id: string) => {
  return BaseService.get(`/institution_api/v1/user_retrospects?start_date=365&user_hashid=${id}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,

      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserTransactionsHeader = (Uid: string, access: string, client: string, id: string) => {
  return BaseService.get(`/institution_api/v1/transactions/appeal_headers?user_hashid=${id}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,

      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserTotalTransactions = (
  Uid: string,
  access: string,
  client: string,
  id: string,
  params: any,
  start: string,
  end: string,
  type: string,
) => {
  return BaseService.get(
    `/institution_api/v1/transactions/totals?user_hashid=${id}&between_search_keys[]=${type}&between_search_values[]=${start}&between_search_values[]=${end}`,
    {
      params,
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const GetUserLogs = (Uid: string, access: string, client: string, id: string) => {
  return BaseService.get(`/institution_api/v1/user_access_logs?user_hashid=${id}`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,

      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetUserTransactions = (
  Uid: string,
  access: string,
  client: string,
  id: string,
  params: any,
  start: string,
  end: string,
  per_page: number,
  page: number,
  type: string,
) => {
  return BaseService.get(
    `/institution_api/v1/transactions?user_hashid=${id}&between_search_keys[]=${type}&between_search_values[]=${start}&between_search_values[]=${end}&per_page=${per_page}&page=${page}`,
    {
      params,
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}

export const GetUserDisallowances = (
  Uid: string,
  access: string,
  client: string,
  id: string,
  start: string,
  end: string,
) => {
  return BaseService.get(
    `/institution_api/v1/disallowances?user_hashid=${id}&per_page=1000&between_search_keys[]=disallowance_date&between_search_values[]=${start}&between_search_values[]=${end}`,
    {
      timeout: 1000000,
      headers: {
        'Content-Type': 'application/json',
        Client: client,
        APP_VERSION: ApiVersion,
        Uid: Uid,
        'Access-Token': access,
      },
    },
  )
}
